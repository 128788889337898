import React, { useState, useEffect, useContext } from "react";
import { Table, Table2 } from "../teacherPages/CheckSubject";
import { useNavigate, useLocation } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import Button from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import ClipLoader from "react-spinners/ClipLoader";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { MyButton, Button2 } from "../../components/Button/Button";
import { Toaster, toast } from "react-hot-toast";

export default function SettingsSpecificResult() {
  const showCustomToast = (type, message) => {
    if (type == 0) {
      toast.error(message, {
        icon: "❗️",
        position: "bottom-center",
      });
    } else {
      toast.success(message, {
        position: "bottom-center",
      });
    }
  };

  const admin_id = localStorage.getItem("adminId");
  const location = useLocation();
  const result = location.state.result;
  const session = location.state.session;
  const is_validated = location.state.is_validated;
  const tempAdminToken = location.state.tempAdminToken;
  const student_img_url = location.state.student_img;

  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { teacherData, admintoken } = values;
  const adminName = localStorage.getItem("name");
  // things handling editing result
  const [editedValues, setEditedValues] = useState({});
  const [editModeIndex, setEditModeIndex] = useState(null);
  const [results, setResults] = useState(result.results);

  const startEditMode = (index, res) => {
    setEditModeIndex(index);
    // Set the initial values when entering edit mode
    setEditedValues({
      first_ca_obj: res.first_ca_obj || 0,
      first_ca_theory: res.first_ca_theory || 0,
      second_ca_obj: res.second_ca_obj || 0,
      second_ca_theory: res.second_ca_theory || 0,
      exam_obj: res.exam_obj || 0,
      exam_theory: res.exam_theory || 0,
    });
  };

  const finishEditMode = (index) => {
    let n_total =
      editedValues.first_ca_obj +
      editedValues.first_ca_theory +
      editedValues.second_ca_obj +
      editedValues.second_ca_theory +
      editedValues.exam_obj +
      editedValues.exam_theory;

    if (n_total <= 100 || n_total < 0) {
      // Update the 'results' state with the edited values
      setResults((prevResults) => {
        const newResults = [...prevResults];

        let grade = "";
        let remark = "";

        if (n_total === 100) {
          grade = "A";
          remark = "Distinction";
        } else if (n_total >= 70) {
          grade = "A";
          remark = "Excellent";
        } else if (n_total >= 60 && n_total <= 69) {
          grade = "B";
          remark = "Very Good";
        } else if (n_total >= 50 && n_total <= 59) {
          grade = "C";
          remark = "Good";
        } else if (n_total >= 40 && n_total <= 49) {
          grade = "D";
          remark = "Fair";
        } else if (n_total <= 39) {
          grade = "F";
          remark = "Fail";
        }

        newResults[index] = {
          ...newResults[index],
          first_ca_obj: editedValues.first_ca_obj,
          first_ca_theory: editedValues.first_ca_theory,
          second_ca_obj: editedValues.second_ca_obj,
          second_ca_theory: editedValues.second_ca_theory,
          exam_obj: editedValues.exam_obj,
          exam_theory: editedValues.exam_theory,
          total: n_total,
          grade: grade,
          remark: remark,
        };
        return newResults;
      });
    } else {
      showCustomToast(
        0,
        "Total score invalid. Please check the entries properly"
      );
    }
    // Exit edit mode
    setEditModeIndex(null);
  };

  const handleInputChange = (e, key) => {
    // Update the edited values when the input changes
    // The 10 here indicates that the string should be interpreted as a decimal number.
    setEditedValues({
      ...editedValues,
      [key]: parseInt(e.target.value, 10) || 0,
    });
  };

  const handleSubmit = () => {
    // Submit the updated 'results' to your backend or wherever needed
    // console.log(result); //old
    // console.log(results); //new
    // Create a new object by spreading properties from temp_result
    let temp_result = result;

    let newObject = {
      ...temp_result,
      // Replace the 'results' property with the new results
      results: [...results],
    };

    console.log(newObject);
  };

  //________________

  const [loading, setLoading] = useState(false);
  // const [promoted, setPromoted] = useState(
  //   result.is_promoted_by != "" && result.is_promoted_by != undefined
  //     ? true
  //     : false
  // );
  // const [repeated, setVerdictTypeed] = useState(
  //   result.is_repeated_by != "" && result.is_repeated_by != undefined
  //     ? true
  //     : false
  // );

  const [verdict, setVerdict] = useState(result.verdict ?? "");

  const [showVerdict, showVerdictDialog] = useState(false);
  const [verdictType, setVerdictType] = useState(""); //repeat_v,promoted_v,validate_result_v

  const handleClose = () => {
    showVerdictDialog(false);
  };

  const openConfirmationDialog = (verdictType) => {
    showVerdictDialog(true);
    setVerdictType(verdictType);
  };
  const dialogStyle = {
    padding: "20px",
  };

  const [validated, setValidated] = useState(false);
  // const [validated, setValidated] = useState(is_validated);
  const handleValidating = (e) => {
    setValidated(e.target.checked);
  };

  const [classTRemark, setClassTRemark] = useState(result.teachers_remark);
  const handleClassTRemarkChange = (e) => {
    setClassTRemark(e.target.value);
  };

  const [overallRemark, setOverallRemark] = useState(result.overall_remark);
  const handleOverallRemarkChange = (e) => {
    setOverallRemark(e.target.value);
  };

  const [attScore, setAttScore] = useState(result.attendance_score);
  const handleAttScoreChange = (e) => {
    setAttScore(e.target.value);
  };

  const [headTRemark, setHeadTRemark] = useState(result.head_teachers_remark);
  const handleheadTRemarkChange = (e) => {
    setHeadTRemark(e.target.value);
  };

  const [affective, setAffective] = useState({
    general_conduct:
      result.affective_traits == null
        ? ""
        : result.affective_traits.general_conduct,
    assignments:
      result.affective_traits == null
        ? ""
        : result.affective_traits.assignments,
    class_participation:
      result.affective_traits == null
        ? ""
        : result.affective_traits.class_participation,
    finishes_work_on_time:
      result.affective_traits == null
        ? ""
        : result.affective_traits.finishes_work_on_time,
    takes_care_of_materials:
      result.affective_traits == null
        ? ""
        : result.affective_traits.takes_care_of_materials,
    working_independently:
      result.affective_traits == null
        ? ""
        : result.affective_traits.working_independently,
  });

  const psychoTraits = result.psycho_motive_traits ?? {};
  const [psycho, setPsycho] = useState({
    regularity: psychoTraits.regularity ?? "",
    personal_cleanliness: psychoTraits.personal_cleanliness ?? "",
    punctuality: psychoTraits.punctuality ?? "",
    completion_of_work: psychoTraits.completion_of_work ?? "",
    // disturbing_others: psychoTraits.disturbing_others ?? "",
    following_instructions: psychoTraits.following_instructions ?? "",
  });

  const handleAffectiveDropdownChange = (e) => {
    const { name, value } = e.target;
    const tAffective = { ...affective };
    tAffective[name] = value;
    setAffective(tAffective);
  };

  const handlePsychoDropdownChange = (e) => {
    const { name, value } = e.target;
    const tPsycho = { ...psycho };
    tPsycho[name] = value;
    setPsycho(tPsycho);
  };

  const [fees, setFees] = useState({
    nextTermFees:
      result.next_term_fee != "" && result.next_term_fee !== undefined
        ? result.next_term_fee
        : "",
    otherFees:
      result.other_fees != "" && result.other_fees !== undefined
        ? result.other_fees
        : "",
    totalFees:
      result.total_fees != "" && result.total_fees !== undefined
        ? result.total_fees
        : "",
  });

  const handleFeesChange = (e) => {
    const { name, value } = e.target;
    const temp = { ...fees };
    temp[name] = value || ""; // Use an empty string if the value is undefined
    setFees(temp);
  };

  // function to update student result for present session
  const promoteOrRepeat = async (v_type) => {
    setLoading(true);

    let total_score = 0;
    let no_of_subjects_passed = 0;
    let no_of_subjects_failed = 0;
    let average = 0;

    // copying other data from the previous result to a new object
    // and adding the new edited result to the new object
    let temp_result = result;
    let updated_result = {
      ...temp_result,
      // Replace the 'results' property with the new results
      results: [...results],
    };

    for (let subjectKey in updated_result.results) {
      // Access the subject object using updated_result.results[subjectKey]
      let subject = updated_result.results[subjectKey];

      total_score += subject["total"];

      if (subject["total"] >= 45) {
        no_of_subjects_passed++;
      } else {
        no_of_subjects_failed++;
      }
    }

    average = total_score / updated_result.results.length;

    // add results, affective_traits, psycho_motive_traits
    const body = {
      token: tempAdminToken,
      total_score: total_score,
      no_of_subjects_passed: no_of_subjects_passed,
      no_of_subjects_failed: no_of_subjects_failed,
      average: average,
      result_id: result._id,
      results: updated_result.results,

      head_teachers_remark: headTRemark,
      teachers_remark: classTRemark,
      overall_remark: overallRemark,
      attendance_score: attScore,
      affective_traits: affective,
      psycho_motive_traits: psycho,

      next_term_fee: fees.nextTermFees,
      other_fees: fees.otherFees,
      total_fees: parseFloat(fees.nextTermFees) + parseFloat(fees.otherFees),
      // verdict: is_repeat ? "REPEATED" : "PROMOTED",
      verdict:
        v_type === "repeat_v"
          ? "REPEATED"
          : verdictType === "promoted_v"
          ? "PROMOTED"
          : "VALIDATED",
      // is_validated: true, //doing this instead after the admin sends the result to the student
      is_validated_by: adminName,
      validator_id: admin_id.replace(/\D/g, ""), // Replace non-digits with an empty string,
    };

    if (v_type === "repeat_v") {
      body.is_repeated_by = adminName;
      body.is_promoted_by = "";
    } else if (v_type === "promoted_v") {
      body.is_promoted_by = adminName;
      body.is_repeated_by = "";
    }
    console.log(body);

    const res = await fetch(
      "https://server-olhms.onrender.com/admin_result/validate_result",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await res.json();

    if (data.msg == "success") {
      result.is_validated_by = adminName;

      if (v_type === "repeat_v") {
        setVerdict("REPEATED");
      } else if (v_type === "promoted_v") {
        setVerdict("PROMOTED");
      } else {
        setVerdict("VALIDATED");
      }

      // if (is_repeat == true) {
      //   setVerdict("REPEATED");
      // } else {
      //   setVerdict("PROMOTED");
      // }

      // console.log(updated_result.verdict);
      updated_result.verdict =
        v_type === "repeat_v"
          ? "REPEATED"
          : verdictType === "promoted_v"
          ? "PROMOTED"
          : "VALIDATED";

      updated_result.head_teachers_remark = headTRemark;
      updated_result.teachers_remark = classTRemark;
      updated_result.overall_remark = overallRemark;
      updated_result.attendance_score = attScore;
      updated_result.affective_traits = affective;
      updated_result.psycho_motive_traits = psycho;

      navigate("/admin/settings-preview-result", {
        state: {
          result: updated_result,
          student_img_url: student_img_url,
          tempAdminToken: tempAdminToken,
          next_term_resumption_date: next_resumption_date,
        },
      });
    } else {
      showCustomToast(0, "Seems an error occured. Please try again");
    }
    handleClose();
    setLoading(false);
  };

  const [next_resumption_date, setNext_resumption_date] = useState("");

  // save resumption date
  const getResumptionDate = async () => {
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_session/get_next_term_resumption_date",
        {
          method: "POST",
          body: JSON.stringify({
            token: tempAdminToken,
            term: result.term,
            session: result.session,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      console.log(data);

      if (data.msg == "success") {
        setNext_resumption_date(data?.next_term_resumption_date);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handle_verdict_submission(e, v_type) {
    e.preventDefault();

    if (typeof headTRemark === "undefined" || headTRemark === "") {
      showCustomToast(0, "Head Teacher Remark cannot be empty");
    } else {
      if (
        typeof fees.nextTermFees === "undefined" ||
        fees.nextTermFees === ""
      ) {
        showCustomToast(0, "Next Term Fees cannot be empty");
      } else {
        if (typeof fees.otherFees === "undefined" || fees.otherFees === "") {
          showCustomToast(0, "Other Fees cannot be empty");
        } else {
          if (validated === true) {
            openConfirmationDialog(v_type);
          } else {
            showCustomToast(0, "Please validate the result first");
          }
        }
      }
    }
  }

  useEffect(() => {
    getResumptionDate();
  }, []);

  const tableValues = [
    { data: "S/N", center: false },
    { data: "Subject", center: false },
    { data: "1st C.A.T (Obj)", center: true },
    { data: "1st C.A.T (Theory)", center: true },
    { data: "2nd C.A.T (Obj)", center: true },
    { data: "2nd C.A.T (Theory)", center: true },
    { data: "Exam (Obj)", center: true },
    { data: "Exam (Theory)", center: true },
    { data: "Total", center: true },
    { data: "Grade", center: true },
    { data: "Remark", center: true },
    // { data: "...", center: true },
  ];

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   let total_score = 0;
  //   let no_of_subjects_passed = 0;
  //   let no_of_subjects_failed = 0;
  //   let average = 0;
  //   for (let subjectKey in result.results) {
  //     // Access the subject object using result.results[subjectKey]
  //     let subject = result.results[subjectKey];

  //     total_score += subject["total"];

  //     if (subject["total"] >= 45) {
  //       no_of_subjects_passed++;
  //     } else {
  //       no_of_subjects_failed++;
  //     }
  //   }

  //   average = total_score / result.results.length;
  // }, []);

  return (
    <div
      className={mobile ? "" : "set-questions"}
      style={mobile ? { margin: 24 } : {}}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <div className="session-flex">
          <div className="cat-session">
            <p>{result.session} Session</p>
          </div>
        </div>
        <div className="show-term">{result.term}</div>
        <div className="submitted-students">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <p>Name: {result.student_name}</p>
              <p>Student ID: {result.student_id}</p>
              <p style={{ color: "#E05615", marginTop: 16, marginBottom: 16 }}>
                A. COGNITIVE REPORT
              </p>
            </div>

            <div>
              <img
                style={{ objectFit: "cover", height: "100px", width: "100px" }}
                src={student_img_url}
                alt="passport"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "5px",
            }}
          >
            <Table2
              columns="0.3fr 2fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 1fr 1fr 0.5fr"
              data={tableValues}
            />

            {/* {result.results.map((res, index) => ( */}
            {results.map((res, index) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns:
                    "0.3fr 2fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 1fr 1fr 0.5fr",
                  fontWeight: "300",
                  gridGap: "30px",
                  // textAlign:"center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div>{index + 1}</div>
                <div>{res.subject}</div>
                {/* <div className="centered-text">
                  {res.first_ca_obj + res.first_ca_theory}
                </div> */}

                <div className="centered-text">
                  {editModeIndex === index ? (
                    <input
                      style={{ width: "50px" }}
                      type="text"
                      value={editedValues.first_ca_obj}
                      onChange={(e) => handleInputChange(e, "first_ca_obj")}
                      maxLength={3}
                    />
                  ) : (
                    res.first_ca_obj || 0
                  )}
                </div>

                <div className="centered-text">
                  {editModeIndex === index ? (
                    <input
                      style={{ width: "50px" }}
                      type="text"
                      value={editedValues.first_ca_theory}
                      onChange={(e) => handleInputChange(e, "first_ca_theory")}
                      maxLength={3}
                    />
                  ) : (
                    res.first_ca_theory || 0
                  )}
                </div>

                <div className="centered-text">
                  {editModeIndex === index ? (
                    <input
                      style={{ width: "50px" }}
                      type="text"
                      value={editedValues.second_ca_obj}
                      onChange={(e) => handleInputChange(e, "second_ca_obj")}
                      maxLength={3}
                    />
                  ) : (
                    res.second_ca_obj || 0
                  )}
                </div>

                <div className="centered-text">
                  {editModeIndex === index ? (
                    <input
                      style={{ width: "50px" }}
                      type="text"
                      value={editedValues.second_ca_theory}
                      onChange={(e) => handleInputChange(e, "second_ca_theory")}
                      maxLength={3}
                    />
                  ) : (
                    res.second_ca_theory || 0
                  )}
                </div>

                <div className="centered-text">
                  {editModeIndex === index ? (
                    <input
                      style={{ width: "50px" }}
                      type="text"
                      value={editedValues.exam_obj}
                      onChange={(e) => handleInputChange(e, "exam_obj")}
                      maxLength={3}
                    />
                  ) : (
                    res.exam_obj || 0
                  )}
                </div>

                <div className="centered-text">
                  {editModeIndex === index ? (
                    <input
                      style={{ width: "50px" }}
                      type="text"
                      value={editedValues.exam_theory}
                      onChange={(e) => handleInputChange(e, "exam_theory")}
                      maxLength={3}
                    />
                  ) : (
                    res.exam_theory || 0
                  )}
                </div>

                {/* <div className="centered-text">
                  {res.second_ca_obj + res.second_ca_theory}
                </div>
                <div className="centered-text">
                  {res.exam_obj + res.exam_theory}
                </div> */}
                <div className="centered-text">{res.total}</div>
                <div className="centered-text">{res.grade}</div>
                <div className="centered-text">{res.remark}</div>
                {/*  */}
                <div>
                  {editModeIndex === index ? (
                    <button onClick={() => finishEditMode(index)}>Save</button>
                  ) : (
                    <button onClick={() => startEditMode(index, res)}>
                      Edit
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <form className="validate-form">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="validate-form-div" style={{ flex: 0.5 }}>
              <label style={{ fontWeight: 500 }}>Class Teachers Remark</label>
              <input
                // disabled={true}
                style={{ fontWeight: 300 }}
                value={classTRemark}
                onChange={handleClassTRemarkChange}
              />
            </div>

            <div className="validate-form-div" style={{ flex: 0.2 }}>
              <label style={{ fontWeight: 500, marginRight: 16 }}>
                Overall Remark
              </label>
              <input
                // disabled={true}
                style={{ fontWeight: 300 }}
                value={overallRemark}
                onChange={handleOverallRemarkChange}
              />
            </div>

            <div className="validate-form-div" style={{ flex: 0.2 }}>
              <label style={{ fontWeight: 500, marginRight: 16 }}>
                Attendance Score
              </label>
              <input
                // disabled={true}
                style={{ fontWeight: 300 }}
                value={attScore}
                onChange={handleAttScoreChange}
              />
            </div>
          </div>
          <div
            style={
              mobile
                ? {
                    display: "flex",
                    flexDirection: "column",
                  }
                : {
                    display: "flex",
                    flex: "1 1",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#E05615",
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                B. AFFECTIVE TRAIT
              </p>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>General Conduct:</p>
                <p
                  style={{
                    marginRight: 60,
                    fontWeight: 500,
                    fontWeight: 300,
                  }}
                >
                  {affective["general_conduct"]}
                </p>
              </div>  */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  General Conduct
                </p>
                <select
                  value={affective["general_conduct"]}
                  name="general_conduct"
                  onChange={(e) => handleAffectiveDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>Assignments</p>
                <select
                  value={affective["assignments"]}
                  name="assignments"
                  onChange={(e) => handleAffectiveDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Class Participation
                </p>
                <select
                  value={affective["class_participation"]}
                  name="class_participation"
                  onChange={(e) => handleAffectiveDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Finishes work on time
                </p>
                <select
                  value={affective["finishes_work_on_time"]}
                  name="finishes_work_on_time"
                  onChange={(e) => handleAffectiveDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Takes care of materials
                </p>
                <select
                  value={affective["takes_care_of_materials"]}
                  name="takes_care_of_materials"
                  onChange={(e) => handleAffectiveDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Working independently
                </p>
                <select
                  value={affective["working_independently"]}
                  name="working_independently"
                  onChange={(e) => handleAffectiveDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                className="validate-form-div"
                style={{ display: "flex", flexDirection: "row", marginTop: 24 }}
              >
                <label style={{ fontWeight: 500, marginRight: 16 }}>
                  Head Teacher Remark
                </label>
                <input
                  style={{ fontWeight: 300 }}
                  value={headTRemark}
                  onChange={handleheadTRemarkChange}
                />
              </div>
            </div>

            <div
              style={
                mobile
                  ? { display: "flex", flexDirection: "column", marginTop: 16 }
                  : { display: "flex", flexDirection: "column" }
              }
            >
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#E05615",
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                C. PSYCHO-MOTIVE TRAIT
              </p>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>Regularity:</p>
                <p
                  style={{
                    marginRight: 60,
                    fontWeight: 500,
                    fontWeight: 300,
                  }}
                >
                  {psycho["regularity"]}
                </p>
              </div>  */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>Regularity</p>
                <select
                  value={psycho["regularity"]}
                  name="regularity"
                  onChange={(e) => handlePsychoDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Personal cleanliness
                </p>
                <select
                  value={psycho["personal_cleanliness"]}
                  name="personal_cleanliness"
                  onChange={(e) => handlePsychoDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>Punctuality</p>
                <select
                  value={psycho["punctuality"]}
                  name="punctuality"
                  onChange={(e) => handlePsychoDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Completion of work
                </p>
                <select
                  value={psycho["completion_of_work"]}
                  name="completion_of_work"
                  onChange={(e) => handlePsychoDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Following instructions
                </p>
                <select
                  value={psycho["following_instructions"]}
                  name="following_instructions"
                  onChange={(e) => handlePsychoDropdownChange(e)}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
              </div>

              <div
                className="validate-form-div"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 24,
                  visibility: "hidden",
                }}
              >
                <label style={{ fontWeight: 500, marginRight: 16 }}>
                  Head Teacher Remark
                </label>
                <input style={{ fontWeight: 300 }} value={"validText"} />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="validate-form-div" style={{ flex: 0.1 }}>
              <label style={{ fontWeight: 500 }}>Next Term Fees</label>
              <input
                style={{ fontWeight: 300 }}
                value={fees.nextTermFees}
                name="nextTermFees"
                onChange={handleFeesChange}
              />
            </div>

            <div className="validate-form-div" style={{ flex: 0.1 }}>
              <label style={{ fontWeight: 500, marginRight: 16 }}>
                Other Fees
              </label>
              <input
                style={{ fontWeight: 300 }}
                value={fees.otherFees}
                name="otherFees"
                onChange={handleFeesChange}
              />
            </div>

            <div className="validate-form-div" style={{ flex: 0.1 }}>
              <label style={{ fontWeight: 500, marginRight: 16 }}>
                Total Fees
              </label>
              <input
                style={{ fontWeight: 300 }}
                value={
                  isNaN(parseFloat(fees.nextTermFees)) ||
                  isNaN(parseFloat(fees.otherFees))
                    ? "Invalid Total"
                    : parseFloat(fees.nextTermFees) + parseFloat(fees.otherFees)
                }
                name="totalFees"
                disabled={true}
                // onChange={handleFeesChange}
              />
            </div>
          </div>

          <div className="validate-box">
            <input
              type="checkbox"
              value="valid"
              checked={validated}
              onChange={handleValidating}
              style={{
                height: "20px",
                width: "20px",
              }}
            />
            <label>I hereby validate this result</label>
          </div>

          <p style={{ fontWeight: "bold" }}>FINAL VERDICT</p>

          {verdict == "VALIDATED" ? (
            <p style={{ color: "#16B943" }}>
              VALIDATED by {result.is_validated_by}
            </p>
          ) : verdict == "PROMOTED" ? (
            <p style={{ color: "#16B943" }}>
              PROMOTED by {result.is_validated_by}
            </p>
          ) : verdict == "REPEATED" ? (
            <p style={{ color: "red" }}>REPEATED by {result.is_validated_by}</p>
          ) : (
            <></>
          )}

          {validated ? (
            <div style={{ alignSelf: "center", marginTop: "20px" }}>
              <button
                className="button-style"
                onClick={(e) => {
                  handle_verdict_submission(e, "repeat_v");
                }}
                disabled={false}
                style={{
                  backgroundColor: loading == true ? "#686363" : "#ED0F0F",
                  border:
                    loading == true ? "1px solid #686363" : "1px solid #ED0F0F",
                  marginRight: "32px",
                }}
              >
                <span>
                  {false ? (
                    <ClipLoader color={"white"} loading={false} size={30} />
                  ) : (
                    "Repeat class"
                  )}
                </span>
              </button>

              <button
                className="button-style"
                onClick={(e) => {
                  handle_verdict_submission(e, "validate_result_v");
                }}
                disabled={false}
                style={{
                  backgroundColor: loading == true ? "#686363" : "#e05616",
                  border:
                    loading == true ? "1px solid #686363" : "1px solid #e05616",
                  marginRight: "32px",
                }}
              >
                <span>
                  {false ? (
                    <ClipLoader color={"white"} loading={false} size={30} />
                  ) : (
                    "Validate Result"
                  )}
                </span>
              </button>

              <button
                className="button-style"
                onClick={(e) => {
                  handle_verdict_submission(e, "promoted_v");
                }}
                disabled={false}
                style={{
                  backgroundColor: loading == true ? "#686363" : "green",
                  border:
                    loading == true ? "1px solid #686363" : "1px solid #16B943",
                }}
              >
                <span>
                  {false ? (
                    <ClipLoader color={"white"} loading={false} size={30} />
                  ) : (
                    "Promote to next class"
                  )}
                </span>
              </button>
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>

      <Dialog onClose={handleClose} open={showVerdict}>
        <DialogTitle>
          {verdictType === "repeat_v"
            ? "Repeat"
            : verdictType === "promoted_v"
            ? "Promote"
            : "Validate"}{" "}
          Child
        </DialogTitle>

        {verdictType === "validate_result_v" ? (
          <p style={dialogStyle}>
            Are you sure you want to validate the result belonging to{" "}
            {result.student_name}, with student ID {result.student_id} for this
            term ?
          </p>
        ) : (
          <p style={dialogStyle}>
            Are you sure you want {result.student_name}, with student ID{" "}
            {result.student_id} to{" "}
            {verdictType === "repeat_v"
              ? "repeat this class"
              : "be promoted to the next class"}
            ?
          </p>
        )}

        <div
          style={{
            marginTop: 16,
            marginBottom: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Button2
            click={(e) => {
              e.preventDefault();
              handleClose();
              // console.log(adminName);
            }}
            name="Cancel"
            style={{ borderColor: "grey", color: "black" }}
          />

          <MyButton
            click={(e) => {
              e.preventDefault();
              promoteOrRepeat(verdictType);
            }}
            name={
              verdictType === "repeat_v"
                ? "Repeat"
                : verdictType === "promoted_v"
                ? "Promote"
                : "Validate"
            }
            button_color={
              verdictType === "repeat_v"
                ? "red"
                : verdictType === "promoted_v"
                ? "green"
                : "#e05616"
            }
            border_color={
              verdictType === "repeat_v"
                ? "red"
                : verdictType === "promoted_v"
                ? "green"
                : "#e05616"
            }
            loading={loading}
          />
        </div>
      </Dialog>
    </div>
  );
}
