import React, { useEffect, useState } from "react";
import "./settings.css";
import { useNavigate } from "react-router-dom";
import Cancel from "../../images/cancel.png";
import Popup from "../../components/Popup/Popup";
import Blur from "react-css-blur";
import { ClipLoader } from "react-spinners";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button, { Button2 } from "../../components/Button/Button";
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import { toast } from "react-hot-toast";

export default function Settings() {
  const navigate = useNavigate();
  const [confirmChangingSession, setConfirmChangingSession] = useState(false);

  const [theSession, setTheSession] = useState("");
  const [theTerm, setTheTerm] = useState("");
  const [theResumptionDate, setTheResumptionDate] = useState("");

  const [term, setTerm] = useState(false);
  const [currentSession, setCurrentSession] = useState(false);
  const [resumptionDate, setResumptionDate] = useState(false);
  const [caSettings, setCaSettings] = useState(false);
  const [grading, setGrading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dialogTitle, setDialogTitle] = useState("Set Term");
  const [dialogBody, setDialogBody] = useState("");

  const [selectedTerm, setSelectedTerm] = useState("First Term");
  const [selectedSession, setSelectedSession] = useState("2023/2024"); // set to current year and next year
  let newDate = new Date();
  const [selectedResumptionDate, setSelectedResumptionDate] = useState(
    `${newDate}`
  );
  const [selectedSettings, setSelectedSettings] = useState({
    first_ca: true,
    second_ca: false,
    exam: false,
  });
  const [selectedCa, setSelectedCa] = useState("1st C.A.T");

  const termOptions = ["First Term", "Second Term", "Third Term"];

  const sessionOptions = [
    "2020/2021",
    "2021/2022",
    "2022/2023",
    "2023/2024",
    "2024/2025",
    "2025/2026",
    "2026/2027",
    "2027/2028",
    "2028/2029",
    "2029/2030",
    "2030/2031",
    "2031/2032",
    "2032/2033",
    "2033/2034",
    "2034/2035",
  ];

  const caOptions = ["None", "1st C.A.T", "2nd C.A.T", "Exam"];

  const [gradingSystem, setGradingSystem] = useState({
    level_one: { score_range: "100", remark: "Distinction", grade: "A" },
    level_two: { score_range: ">= 70 ", remark: "Excellent", grade: "A" },
    level_three: { score_range: "60-69", remark: "Very Good", grade: "B" },
    level_four: { score_range: "50-59", remark: "Good", grade: "C" },
    level_five: { score_range: "40-49", remark: "Fair", grade: "D" },
    level_six: { score_range: "<= 39", remark: "Fail", grade: "F" },
  });

  const handleGradingChange = (e) => {
    const { value, name } = e.target;
    const newGradeSystem = { ...gradingSystem };
    newGradeSystem[name.split(".")[0]][name.split(".")[1]] = value;
    setGradingSystem(newGradeSystem);
  };

  const [openDialog, handleDisplay] = useState(false);

  const handleClose = () => {
    handleDisplay(false);
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };
  const dialogStyle = {
    padding: "20px",
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // view current session
  // const viewCurrentSession = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await fetch("https://server-olhms.onrender.com/admin_session/view_current_session", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         token: tempAdminToken
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json, text/plain, */*",
  //       },
  //     });
  //     const data = await res.json();

  //     if (data.msg == "Success") {

  //       const session = data?.sessionM;

  //       setTheSession(session.session);
  //       setTheTerm(session.current_term);
  //       setTheResumptionDate(session.next_term_resumption_date);

  //       setLoading(false);
  //       console.log(data);
  //     } else {
  //       // alert("error logging in");
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     //alert("error logging in");
  //     console.log(error);
  //     setLoading(false);
  //   }
  // }

  // useEffect(()=>{
  //   viewCurrentSession();
  // }, []);

  // current admin details
  let [tempAdminToken, setTempAdminToken] = useState("");
  let [tempAdminName, setTempAdminName] = useState("");
  let [tempAdminId, setTempAdminId] = useState("");
  let [tempAdminImg, setTempAdminImg] = useState("");

  // master admin login
  const masterAdminLogin = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_auth/login",
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
            password: password,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      if (data.msg == "Success") {
        const adminRoles = data?.admin.roles;
        if (adminRoles.includes("master")) {
          // admin is master admin
          setTempAdminToken(data?.token);
          setTempAdminName(`${data?.admin.firstname} ${data?.admin.lastname}`);
          setTempAdminId(data?.admin.staff_id);
          setTempAdminImg(data?.admin.img_url);

          setTheSession(data?.current_session.session);
          setTheTerm(data?.current_session.current_term);
          setTheResumptionDate(data?.current_session.next_term_resumption_date);

          setSelectedSession(data?.current_session.session);
          setSelectedTerm(data?.current_session.current_term);

          setSelectedSettings({
            first_ca: data?.settings.first_ca_active,
            second_ca: data?.settings.second_ca_active,
            exam: data?.settings.examination_active,
          });

          setGradingSystem(data?.settings.grading_system);

          if (data?.settings.first_ca_active) {
            setSelectedCa("1st C.A.T");
          }

          if (data?.settings.second_ca_active) {
            setSelectedCa("2nd C.A.T");
          }

          if (data?.settings.examination_active) {
            setSelectedCa("Exam");
          }

          toast.success("Login successful", {
            position: "bottom-center",
          });
          setLoggedIn(true);
          setEmail("");
          setPassword("");
        } else {
          // admin is not master admin
          alert("This level is restricted to master admins only");
        }
        setLoading(false);
        console.log(data);
      } else {
        alert(data.msg);
        setLoading(false);
      }
    } catch (error) {
      alert("oOps! An error occured. Please try again");
      console.log(error);
      setLoading(false);
    }
  };

  // save session
  const saveSession = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_session/create_session",
        {
          method: "POST",
          body: JSON.stringify({
            token: tempAdminToken,
            session: selectedSession,
            admin_name: tempAdminName,
            admin_img: tempAdminImg,
            admin_id: tempAdminId,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      if (data.msg == "success") {
        const session = data?.sessionM;

        setTheSession(session.session);
        setTheTerm(session.current_term);
        setTheResumptionDate(session.next_term_resumption_date);

        setLoading(false);
        handleClose();
        setCurrentSession(false);
        console.log(data);
      } else {
        //alert("error logging in");
        setLoading(false);
      }
    } catch (error) {
      //alert("error logging in");
      console.log(error);
      setLoading(false);
    }
  };

  // save current term
  const saveTerm = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_session/set_current_term",
        {
          method: "POST",
          body: JSON.stringify({
            token: tempAdminToken,
            current_term: selectedTerm,
            session: selectedSession,
            admin_name: tempAdminName,
            admin_img: tempAdminImg,
            admin_id: tempAdminId,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      if (data.msg == "success") {
        const current_term = data?.current_term;

        setTheTerm(current_term);

        setLoading(false);
        handleClose();
        console.log(data);
      } else {
        //alert("error logging in");
        setLoading(false);
      }
    } catch (error) {
      //alert("error logging in");
      console.log(error);
      setLoading(false);
    }
  };

  // save resumption date
  const saveResumptionDate = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_session/set_next_term_resumption_date",
        {
          method: "POST",
          body: JSON.stringify({
            token: tempAdminToken,
            next_term_resumption_date: selectedResumptionDate,
            session: selectedSession,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      if (data.msg == "success") {
        const next_term_resumption_date = data?.next_term_resumption_date;

        setSelectedResumptionDate(next_term_resumption_date);
        setTheResumptionDate(next_term_resumption_date);

        setLoading(false);
        handleClose();
        console.log(data);
      } else {
        //alert("error logging in");
        setLoading(false);
      }
    } catch (error) {
      //alert("error logging in");
      console.log(error);
      setLoading(false);
    }
  };

  // save current term
  const setCAExamSettings = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_settings/activate_ca_exam",
        {
          method: "POST",
          body: JSON.stringify({
            token: tempAdminToken,
            ca_type: selectedCa,
            admin_name: tempAdminName,
            admin_img: tempAdminImg,
            admin_id: tempAdminId,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      if (data.msg == "success") {
        const settings = data?.settings;

        setSelectedSettings({
          first_ca: settings.first_ca_active,
          second_ca: settings.second_ca_active,
          exam: settings.examination_active,
        });

        setCaSettings(false);
        setLoading(false);
        handleClose();
        console.log(data);
      } else {
        alert("It seems an error occured");
        setLoading(false);
      }
    } catch (error) {
      //alert("error logging in");
      alert("oOps! An error occured");

      console.log(error);
      setLoading(false);
    }
  };

  // save grading system
  const saveGradingSystem = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_settings/set_grading_system",
        {
          method: "POST",
          body: JSON.stringify({
            token: tempAdminToken,
            grading_system: gradingSystem,
            admin_name: tempAdminName,
            admin_img: tempAdminImg,
            admin_id: tempAdminId,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      if (data.msg == "success") {
        setGradingSystem(data?.settings.grading_system);

        setLoading(false);
        handleClose();
        console.log(data);
      } else {
        //alert("error logging in");
        setLoading(false);
      }
    } catch (error) {
      //alert("error logging in");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loggedIn ? (
        <></>
      ) : (
        <div className="sign-box">
          <form className="portal-form" onSubmit={"handleSubmit"}>
            <div className="form-group">
              <label htmlFor="student">{"Email"}</label>
              <input
                name={"email"}
                type="text"
                value={email}
                placeholder="Enter Email"
                onChange={handleEmail}
              />
            </div>
            <div className="form-group">
              <label htmlFor="student">Password</label>
              <input
                name="password"
                type="password"
                value={password}
                placeholder="Enter Password"
                onChange={handlePassword}
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                masterAdminLogin();
              }}
              disabled={loading}
            >
              <span>
                {loading ? <ClipLoader color={"white"} size={30} /> : "Login"}
              </span>
            </button>
          </form>
        </div>
      )}
      <Blur radius={!loggedIn ? "5px" : "0"} transition="400ms">
        <div
          className="set-questions"
          style={{
            height: "100%",
            alignItems: "flex-start",
          }}
        >
          <div className="set-container">
            <div className="settings-container">
              <div className="settings-row">
                <div>
                  <p className="setting-topic">Current Session</p>
                  <p className="editable">Edit current session</p>
                </div>
                <div
                  className="display-setting"
                  onClick={() => {
                    setConfirmChangingSession(true);
                    setTerm(false);
                    setResumptionDate(false);
                    setCaSettings(false);
                    setGrading(false);
                  }}
                >
                  {theSession}
                </div>
              </div>
              <div className="settings-row">
                <div>
                  <p className="setting-topic">Current Term</p>
                  <p className="editable">Edit current term</p>
                </div>
                <div
                  className="display-setting"
                  onClick={() => {
                    setTerm(true);
                    setCurrentSession(false);
                    setResumptionDate(false);
                    setCaSettings(false);
                    setGrading(false);
                  }}
                >
                  {theTerm}
                </div>
              </div>
              {/* <div className="settings-row">
            <div>
              <p className="setting-topic">Grading System</p>
              <p className="editable">Edit school grading system</p>
            </div>
            <div onClick={(e)=>{
              setGrading(true);
              setResumptionDate(false);
              setTerm(false);
              setCurrentSession(false);
              setCaSettings(false);
              setDialogTitle('');
              openDialogBox();
            }} className="display-setting">70- Excellent</div>
          </div> */}
              <div className="settings-row">
                <div>
                  <p className="setting-topic">Next Term Begins</p>
                  <p className="editable">Edit second term resumption date</p>
                </div>
                <div>
                  <DatePicker
                    id="datepicker-id"
                    name="date-demo"
                    onChange={(e) => {
                      setSelectedResumptionDate(e.target.value);
                      setTheResumptionDate(e.target.value);
                      setResumptionDate(true);
                      setTerm(false);
                      setCurrentSession(false);
                      setCaSettings(false);
                      setGrading(false);
                      setDialogTitle("Set Resumption Date");
                      setDialogBody(
                        `Are you sure you want to set the next termp to begin ${selectedResumptionDate}?`
                      );
                      openDialogBox();
                    }}
                    placeholder={theResumptionDate}
                    value={theResumptionDate}
                  />
                </div>
              </div>
              <div className="settings-row">
                <div>
                  <p className="setting-topic">Frequently Asked Questions</p>
                  <p className="editable">Edit F.A.Q's</p>
                </div>
                <div
                  className="display-setting"
                  onClick={() => navigate("/admin/view-faq")}
                >
                  Edit
                </div>
              </div>
              <div className="settings-row">
                <div>
                  <p className="setting-topic">Centres</p>
                  <p className="editable">View all centres</p>
                </div>
                <div
                  className="display-setting"
                  onClick={() =>
                    navigate("/admin/view-center", {
                      state: {
                        tempAdminToken,
                        tempAdminId,
                        tempAdminImg,
                        tempAdminName,
                      },
                    })
                  }
                >
                  View
                </div>
              </div>
              <div className="settings-row">
                <div>
                  <p className="setting-topic">Activate Assessments</p>
                  <p className="editable">
                    {" "}
                    Make Visible Continuous Assessment Tests or Exam Questions
                    on Mobile app
                  </p>
                </div>
                <div
                  className="display-setting"
                  onClick={() => {
                    setCaSettings(true);
                    setTerm(false);
                    setCurrentSession(false);
                    setResumptionDate(false);
                    setGrading(false);
                  }}
                >
                  Select
                </div>
              </div>
            </div>

            <Popup isOpen={confirmChangingSession}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  NOTE
                </p>

                <p style={{ textAlign: "center" }}>
                  After selecting a new session and proceeding with this action,
                  <br />
                  all student login codes will be reset, and they will be
                  required
                  <br />
                  to make a new payment for school fees to regain access
                  <br />
                  to class resources in the app.
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: "20px",
                  }}
                >
                  <Button2
                    click={(e) => {
                      e.preventDefault();
                      setConfirmChangingSession(false);
                    }}
                    name="Cancel"
                    style={{ marginRight: "30px" }}
                  />

                  <Button
                    click={(e) => {
                      e.preventDefault();
                      setConfirmChangingSession(false);
                      setCurrentSession(true);
                    }}
                    name="Continue"
                  />
                </div>
              </div>
            </Popup>

            <Popup isOpen={term}>
              <form className="add-subject-form">
                <div className="add-remove">
                  <p className="addition-text"></p>
                  <div className="close-popup" onClick={() => setTerm(false)}>
                    <img src={Cancel} alt="" />
                  </div>
                </div>
                <div className="subject-form term-form">
                  {termOptions.map((term, index) => (
                    <div
                      key={index}
                      style={{
                        color: term == selectedTerm ? "#E05615" : "black",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTerm(term);
                      }}
                    >
                      {term}
                    </div>
                  ))}
                </div>
                <div style={{ alignSelf: "center" }}>
                  <Button
                    click={(e) => {
                      e.preventDefault();
                      setDialogTitle("Set Term");
                      setDialogBody(
                        `Are you sure you want to set the current term to ${selectedTerm}? \nNB: This action will reset all students login code and they will all be required to make a new payment for school fees to regain access to class resources in the app.`
                      );
                      openDialogBox();
                    }}
                    name="Set"
                  />
                </div>
              </form>
            </Popup>

            <Popup isOpen={currentSession}>
              <form className="add-subject-form">
                <div className="add-remove">
                  <p className="addition-text"></p>
                  <div
                    className="close-popup"
                    onClick={() => setCurrentSession(false)}
                  >
                    <img src={Cancel} alt="" />
                  </div>
                </div>
                <div className="subject-form term-form">
                  {sessionOptions.map((session, index) => (
                    <div
                      key={index}
                      style={{
                        color: session == selectedSession ? "#E05615" : "black",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedSession(session);
                      }}
                    >
                      {session}
                    </div>
                  ))}
                </div>
                <div style={{ alignSelf: "center" }}>
                  <Button
                    click={(e) => {
                      e.preventDefault();
                      setDialogTitle("Set Session");
                      setDialogBody(
                        `Are you sure you want to set the current session to ${selectedSession} academic session?`
                      );
                      openDialogBox();
                    }}
                    name="Set"
                  />
                </div>
              </form>
            </Popup>

            <Popup isOpen={caSettings}>
              <form className="add-subject-form">
                <div className="add-remove">
                  <p className="addition-text"></p>
                  <div
                    className="close-popup"
                    onClick={() => setCaSettings(false)}
                  >
                    <img src={Cancel} alt="" />
                  </div>
                </div>
                <div className="subject-form term-form">
                  {caOptions.map((ca, index) => (
                    <div
                      key={index}
                      style={{
                        color: ca == selectedCa ? "#E05615" : "black",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedCa(ca);
                      }}
                    >
                      {ca}
                    </div>
                  ))}
                </div>
                <div style={{ alignSelf: "center" }}>
                  <Button
                    click={(e) => {
                      let title =
                        selectedCa == "None"
                          ? "Deactivate All?"
                          : `Activate ${selectedCa}`;
                      let content =
                        selectedCa == "None"
                          ? "Are you sure you want to make 1st Test, 2nd Test & Exam unaccessible on the mobile app to all the students?"
                          : `Are you sure you want the ${
                              selectedCa.split(" ")[0]
                            } Assessment Test questions to be visible on the mobile app to all students?`;
                      e.preventDefault();
                      setDialogTitle(title);
                      setDialogBody(content);
                      openDialogBox();
                    }}
                    name="Set"
                  />
                </div>
              </form>
            </Popup>

            <Dialog onClose={handleClose} open={openDialog}>
              <DialogTitle>{dialogTitle}</DialogTitle>
              {grading ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginBottom: 10,
                    }}
                  >
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_one.score_range"}
                      value={gradingSystem.level_one.score_range}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 120, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_one.remark"}
                      value={gradingSystem.level_one.remark}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_one.grade"}
                      value={gradingSystem.level_one.grade}
                      onChange={handleGradingChange}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginBottom: 10,
                    }}
                  >
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_two.score_range"}
                      value={gradingSystem.level_two.score_range}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 120, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_two.remark"}
                      value={gradingSystem.level_two.remark}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_two.grade"}
                      value={gradingSystem.level_two.grade}
                      onChange={handleGradingChange}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginBottom: 10,
                    }}
                  >
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_three.score_range"}
                      value={gradingSystem.level_three.score_range}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 120, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_three.remark"}
                      value={gradingSystem.level_three.remark}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_three.grade"}
                      value={gradingSystem.level_three.grade}
                      onChange={handleGradingChange}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginBottom: 10,
                    }}
                  >
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_four.score_range"}
                      value={gradingSystem.level_four.score_range}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 120, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_four.remark"}
                      value={gradingSystem.level_four.remark}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_four.grade"}
                      value={gradingSystem.level_four.grade}
                      onChange={handleGradingChange}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginBottom: 10,
                    }}
                  >
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_five.score_range"}
                      value={gradingSystem.level_five.score_range}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 120, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_five.remark"}
                      value={gradingSystem.level_five.remark}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_five.grade"}
                      value={gradingSystem.level_five.grade}
                      onChange={handleGradingChange}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginBottom: 10,
                    }}
                  >
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_six.score_range"}
                      value={gradingSystem.level_six.score_range}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 120, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_six.remark"}
                      value={gradingSystem.level_six.remark}
                      onChange={handleGradingChange}
                    />
                    <input
                      style={{ width: 60, margin: 8 }}
                      type="text"
                      disabled={loading}
                      name={"level_six.grade"}
                      value={gradingSystem.level_six.grade}
                      onChange={handleGradingChange}
                    />
                  </div>
                </div>
              ) : (
                <p style={dialogStyle}>{dialogBody}</p>
              )}

              {grading ? (
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={loading}
                    click={(e) => {
                      e.preventDefault();
                      saveGradingSystem();
                    }}
                    name="Save"
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    disabled={loading}
                    click={(e) => {
                      e.preventDefault();

                      if (term) {
                        // save term
                        saveTerm();
                      }

                      if (currentSession) {
                        // save current session
                        saveSession();
                      }

                      if (resumptionDate) {
                        // save resumption date
                        saveResumptionDate();
                      }

                      if (caSettings) {
                        setCAExamSettings();
                      }
                    }}
                    name="Yes"
                  />
                </div>
              )}
            </Dialog>
          </div>
        </div>
      </Blur>
    </div>
  );
}
